import axios from "axios";
import end from './endpoint';
import auth from "./auth";
const ENDPOINT = end.PATH + "students/";

export default {
    get() {
        const token = auth.getSession();
        return axios.get(ENDPOINT, { headers: { token } });
    },

    getApplicants() {
        const token = auth.getSession();
        return axios.get(ENDPOINT + "state/applicants", { headers: { token } });
    },

    getOne(id) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + id, { headers: { token } });
    },

    getByName(paternal, maternal, names) {
        const token = auth.getSession();
        return axios.get(ENDPOINT + paternal + "/" + maternal + "/" + names, { headers: { token } });
    },

    insert(student) {
        const token = auth.getSession();
        return axios.post(ENDPOINT, student, { headers: { token } });
    },

    update(id, student) {
        const token = auth.getSession();
        return axios.put(ENDPOINT + id, student, { headers: { token } });
    },

    delete(id) {
        const token = auth.getSession();
        return axios.delete(ENDPOINT + id, { headers: { token } });
    },

    // Nuevos metodos para validación de QR (28 de febrero)

    validReceipt(ins) {
        const token = auth.getSession();
        return axios.get(`${end.RPT_PATH}validation/receipt/${ins}/0`, { headers: { token } });
    },

    validConstancy(student, period) {
        const token = auth.getSession();
        return axios.get(`${end.RPT_PATH}validation/constancy/${student}/${period}`, { headers: { token } });
    },
};