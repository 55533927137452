<template>
    <div class="contain" style="height: 100%">
        <v-progress-linear fixed :active="loading" :indeterminate="true"></v-progress-linear>
        <v-fade-transition>
            <v-container v-show="compiler" fluid fill-height>
                <v-row justify="center">
                    <v-col style="width: 100%; max-width: 520px">
                        <v-card style="border-radius:10px" :style="{
                            background: $vuetify.theme.themes[theme].images,
                        }">
                            <v-card-text>
                                <div class="text-subtitle-2 mb-4">
                                    <v-avatar class="mr-2">
                                        <v-img :src="`${api}${school.logotype}`"></v-img>
                                    </v-avatar>
                                    <span class="font-weight-black">{{ school.clave }}</span>
                                    {{ school.school }}
                                </div>
                                <div class="valid" v-if="valid">
                                    <div class="text-h5 mb-2">
                                        <v-icon class="mr-2" color="info">mdi-check-decagram-outline</v-icon>
                                        Recibo válido
                                    </div>
                                    <div class="text-body-2">
                                        Expedido para:
                                        <span class="font-weight-black">
                                            {{ `${student.paternal_surname} ${student.maternal_surname}
                                            ${student.names}` }}
                                        </span>
                                    </div>
                                    <div class="text-body-2">
                                        CURP:
                                        <span class="font-weight-black">
                                            {{ student.curp }}
                                        </span>
                                    </div>
                                    <div class="text-body-2">
                                        Matrícula:
                                        <span class="font-weight-black">
                                            {{ student.enrollment }}
                                        </span>
                                    </div>

                                    <div class="text-body-2 mt-4">
                                        Semestre cursado:
                                        <span class="font-weight-black text-h6">
                                            {{ inscription[0].semester }}°
                                        </span> grupo:
                                        <span class="font-weight-black text-h6">
                                            {{ inscription[0].groups }}°
                                        </span>
                                    </div>
                                </div>
                                <div class="no-valid" v-if="!valid">
                                    <div class="text-center">
                                        <v-icon size="x-large" color="red">
                                            mdi-close
                                        </v-icon>
                                    </div>
                                    <div class="text-h5 text-center mt-4">
                                        Recibo NO VÁLIDO
                                    </div>
                                    <div class="text-body-2 text-center mb-4">
                                        Comuníquelo a administración escolar
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-fade-transition>
    </div>
</template>

<script>
import endpoint from "../../../plugins/endpoint";
import auth from "../../../plugins/auth";
import students from "../../../plugins/students";
import settings from "../../../plugins/settings";

export default {
    name: "Auth",

    created() {
        this.main();
    },

    computed: {
        theme() {
            return this.$vuetify.theme.dark ? "dark" : "light";
        },
    },

    data: () => ({
        inscription: [],
        student: {},
        school: {},
        valid: false,

        alert: false,
        error: "",
        loading: false,
        compiler: false,

        api: ''
    }),

    methods: {
        // start chain
        async main() {
            this.api = endpoint.BASE_PATH;
            this.loading = true;
            await students.validReceipt(this.$route.params.inscription).then(async (response) => {
                if (response.data.state !== -1) {
                    this.valid = true;
                    this.inscription = response.data.receipt;
                    this.student = response.data.student;

                    await this.getSchool();
                }
                this.finish();
            });
        },
        async getSchool() {
            this.loading = true;
            await settings.getOne(1).then((response) => {
                this.school = response.data;
                this.finish();
            });
        },

        finish() {
            this.compiler = true;
            this.loading = false;
        },
        showAlert(message) {
            this.error = message;
            this.alert = true;
            setTimeout(() => (this.alert = false), 3000);
        },
    },
};
</script>