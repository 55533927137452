<template>
  <div class="contain">
    <v-progress-linear fixed :active="loading" :indeterminate="true"></v-progress-linear>
    <v-divider></v-divider>
    <v-toolbar flat :style="{
      background: $vuetify.theme.themes[theme].background,
    }">
      <v-app-bar-nav-icon class="ml-1">
        <v-btn @click="back()" icon>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="text-subtitle-1 font-weight-medium">
        Alumno
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="status_expand = !status_expand" v-show="!insert_ins" color="primary" dark elevation="0"
        class="mr-2">
        <div class="text-subtitle-2 mx-2" style="text-transform: none">
          Estado
          <v-icon class="ml-2">
            {{ status_expand ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </div>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-container fluid>
      <v-slide-y-reverse-transition>
        <v-row justify="center" v-show="status_expand">
          <v-col cols="12" md="9" lg="8" xl="7">
            <v-card style="border-radius: 10px" :style="{
              background: $vuetify.theme.themes[theme].images,
            }">
              <v-card-text>
                <v-row align="center">
                  <v-col cols="12" md="6">
                    <div class="text-subtitle-2">
                      <v-icon color="info" class="mr-2">mdi-school-outline</v-icon>
                      Estado académico
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-select v-model="selectedPeriod" v-on:input="getStatus(false)" :items="periods"
                          item-value="period" item-text="period" outlined dense hide-details label="Periodo"></v-select>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select v-model="period_model.partial" v-on:input="getStatus(false)" :items="partials"
                          item-value="id" item-text="name" outlined dense hide-details label="Parcial"></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-title class="text-subtitle-2 text--primary">
                  {{ student.paternal_surname }}
                  {{ student.maternal_surname }} {{ student.names }} - Cardex
                </v-list-item-title>
                <v-list-item-action>
                  <v-btn @click="ratingsPDF()" :disabled="!selectedActive" icon color="primary">
                    <v-icon>mdi-download-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-row no-gutters>
                <v-col cols="12" sm="6" lg="4" v-for="cardex in cardex_list" :key="cardex.id">
                  <v-card @click="
                    openStatus(
                      cardex.icon,
                      cardex.name,
                      cardex.teacher,
                      cardex.mail,
                      cardex.rating_number,
                      cardex.rating_letter,
                      cardex.fouls,
                      cardex.evidences,
                      cardex.evidences_value,
                      cardex.exam,
                      cardex.exam_value,
                      cardex.lesson,
                      cardex.complementary
                    )
                    " link outlined style="border-radius: 10px" :style="{
                      background: $vuetify.theme.themes[theme].images,
                    }">
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-img lazy-src="../../../../assets/images/default.png"
                          :src="back_url + cardex.profile_image"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-title class="text-caption font-weight-medium">
                        Por: {{ cardex.teacher }}
                      </v-list-item-title>
                    </v-list-item>
                    <div class="text-center">
                      <v-avatar size="120" class="my-4">
                        <v-img lazy-src="../../../../assets/images/default.png" :src="require('../../../../assets/subjects/' +
                          cardex.icon +
                          '.png')
                          "></v-img>
                      </v-avatar>
                    </div>
                    <v-card-text>
                      <div class="text-subtitle-2 text--primary text-truncate">
                        {{ cardex.name }}
                      </div>
                      <div class="text-h6 font-weight-bold" :class="cardex.rating_number >= 6
                        ? 'text--primary'
                        : 'error--text'
                        ">
                        {{
                          cardex.complementary
                            ? cardex.rating_number >= 6
                              ? "A"
                              : "N/A"
                            : cardex.rating_number
                        }}
                      </div>
                      <div class="text-caption text-truncate">
                        {{
                          cardex.complementary
                            ? cardex.rating_number >= 6
                              ? "APROBADA"
                              : "NO APROBADA"
                            : cardex.rating_letter
                        }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-slide-y-reverse-transition>
      <v-slide-y-reverse-transition>
        <v-row justify="center" class="my-8" v-show="compiler">
          <v-col cols="12" md="9" lg="8" xl="7">
            <v-row>
              <v-col cols="12" md="4" lg="3">
                <div class="text-center">
                  <v-avatar size="150">
                    <v-img lazy-src="../../../../assets/images/default.png" :src="photo_url"></v-img>
                  </v-avatar>
                </div>
                <div class="text-center">
                  <v-btn @click="custom = !custom" color="primary" outlined class="mt-8 mb-4" :disabled="insert">
                    <div class="text-subtitle-2" style="text-transform: none">
                      Cambiar fotografía
                    </div>
                    <v-icon class="ml-4">
                      {{ custom ? "mdi-chevron-up" : "mdi-chevron-down" }}
                    </v-icon>
                  </v-btn>
                </div>
                <v-expand-transition>
                  <div v-show="custom">
                    <v-file-input v-model="photo" label="Seleccionar nueva" accept="image/png, image/jpeg"
                      hide-details></v-file-input>
                    <v-btn @click="uploadPhoto()" color="primary" dark class="my-4">
                      <div class="text-subtitle-2" style="text-transform: none">
                        Guardar nueva
                      </div>
                    </v-btn>
                  </div>
                </v-expand-transition>
              </v-col>
              <v-col cols="12" md="8" lg="9">
                <div class="text-h5 font-weight-bold text--primary">
                  {{
                    student.enrollment
                      ? "Estudiante " + student.enrollment
                      : "Aspirante" + student.enrollment
                  }}
                </div>
                <v-divider class="mt-4"></v-divider>
                <v-list-item @click="data_expand = !data_expand" link>
                  <v-list-item-avatar>
                    <v-icon>mdi-form-select</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title class="text-subtitle-2">
                    Datos personales ({{ student.paternal_surname }}
                    {{ student.maternal_surname }} {{ student.names }})
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-icon>
                      {{ data_expand ? "mdi-chevron-up" : "mdi-chevron-down" }}
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
                <v-expand-transition>
                  <div class="data" v-show="data_expand">
                    <v-text-field v-model="student.paternal_surname" hide-details filled dense
                      label="Apellido paterno *" class="my-4"></v-text-field>
                    <v-text-field v-model="student.maternal_surname" hide-details filled dense
                      label="Apellido materno *"></v-text-field>
                    <v-text-field v-model="student.names" hide-details filled dense label="Nombre(s) *"
                      class="my-4"></v-text-field>
                    <v-select :items="genders" item-value="id" item-text="name" v-model="student.gender" hide-details
                      filled dense label="Genero"></v-select>
                    <v-text-field v-model="student.phone"
                      onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                      maxlength="10" hide-details filled dense label="Teléfono" class="my-4"></v-text-field>
                    <div class="text-subtitle-2 mt-8 mb-4">
                      Correo electrónico (medio de contacto)
                    </div>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field v-model="st_mail_name" hide-details filled
                          label="Correo electrónico *"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select v-model="st_mail_domain" :items="mails" hide-details outlined single-line
                          prefix="@"></v-select>
                      </v-col>
                    </v-row>
                    <div class="text-body-2 mt-3 mb-4">
                      Registrado anteriormente:
                      <span class="font-weight-bold" :class="student.mail ? 'info--text' : 'error--text'">
                        {{
                          student.mail
                            ? student.mail
                            : "Sin correo (importante)"
                        }}
                      </span>
                    </div>
                    <div class="text-subtitle-2 mt-8 mb-4">
                      Información del acta de nacimiento
                    </div>
                    <v-text-field v-model="student.birth_date" type="date" hide-details filled dense
                      label="Fecha de nacimiento"></v-text-field>
                    <v-text-field v-model="student.birth_city" hide-details filled dense label="Localidad de nacimiento"
                      class="my-4"></v-text-field>
                    <v-text-field v-model="student.birth_municipality" hide-details filled dense
                      label="Municipio de nacimiento"></v-text-field>
                    <v-text-field v-model="student.birth_state" hide-details filled dense label="Entidad de nacimiento"
                      class="my-4"></v-text-field>
                    <v-text-field v-model="student.nationality" hide-details filled dense
                      label="Nacionalidad"></v-text-field>
                    <v-text-field v-model="student.curp" maxlength="18" hide-details filled dense
                      label="Clave única de registro de población *" class="mt-4"></v-text-field>
                    <div class="text-subtitle-2 mt-8 mb-4">
                      Domicilio actual
                    </div>
                    <v-text-field v-model="student.street" hide-details filled dense
                      label="Calle o avenida"></v-text-field>
                    <v-text-field v-model="student.number"
                      onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                      maxlength="10" hide-details filled dense label="Número" class="my-4"></v-text-field>
                    <v-text-field v-model="student.postal_code"
                      onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                      maxlength="5" hide-details filled dense label="Código postal"></v-text-field>
                    <v-text-field v-model="student.colony" hide-details filled dense label="Colonia"
                      class="my-4"></v-text-field>
                    <v-text-field v-model="student.city" hide-details filled dense
                      label="Ciudad o localidad"></v-text-field>
                    <v-text-field v-model="student.municipality" hide-details filled dense label="Municipio"
                      class="mt-4"></v-text-field>
                    <div class="text-subtitle-2 mt-8 mb-4">
                      Salud y servicios medicos
                    </div>
                    <v-select v-model="student.blood_type" :items="blood_types" hide-details filled dense
                      label="Tipo sanguíneo"></v-select>
                    <v-checkbox v-model="student.imss" hide-details label="IMSS" class="my-4"></v-checkbox>
                    <v-checkbox v-model="student.issste" hide-details label="ISSSTE"></v-checkbox>
                    <v-checkbox v-model="student.insabi" hide-details label="INSABI" class="my-4"></v-checkbox>
                    <v-checkbox v-model="student.other" hide-details label="Otro"></v-checkbox>
                    <v-text-field v-model="student.social_security"
                      onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                      maxlength="11" hide-details filled dense label="Número de seguro social"
                      class="my-4"></v-text-field>
                    <v-expand-transition>
                      <div v-show="error">
                        <v-alert dense text type="error">
                          <div class="text-subtitle-2">{{ errorInfo }}</div>
                        </v-alert>
                      </div>
                    </v-expand-transition>
                    <v-btn @click="saveStudent()" color="primary" dark elevation="0" class="my-4">
                      <div class="text-subtitle-2 mx-2" style="text-transform: none">
                        Actualizar estos datos
                      </div>
                    </v-btn>
                    <div class="text-h5 font-weight-bold text--primary mt-10">
                      Tutor(es)
                    </div>
                    <div class="text-subtitle-2 mt-2 mb-4">
                      Obligatoriamente se debe registrar al menos 1 tutor por
                      alumno
                    </div>
                    <v-card style="border-radius: 10px" :style="{
                      background: $vuetify.theme.themes[theme].images,
                    }">
                      <v-list-item @click="getTutor(tutor.id)" v-for="tutor in tutors" :key="tutor.id" link>
                        <v-list-item-avatar>
                          <v-icon>mdi-account-circle</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title class="text-subtitle-2">
                          {{ tutor.paternal_surname }}
                          {{ tutor.maternal_surname }} {{ tutor.names }}
                        </v-list-item-title>
                        <v-list-item-action>
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </v-card>
                    <v-card style="border-radius: 10px" :style="{
                      background: $vuetify.theme.themes[theme].images,
                    }" class="my-5">
                      <v-list-item @click="expandTutor()" link>
                        <v-list-item-title class="text-subtitle-2">
                          {{ insert_tutor ? "Nuevo tutor" : "Datos del tutor" }}
                        </v-list-item-title>
                        <v-list-item-action>
                          <v-icon>
                            {{
                              tutor_expand
                                ? "mdi-chevron-up"
                                : "mdi-chevron-down"
                            }}
                          </v-icon>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-expand-transition>
                        <v-card-text v-show="tutor_expand">
                          <v-text-field v-model="tutor.paternal_surname" hide-details filled dense
                            label="Apellido paterno *" class="mb-4"></v-text-field>
                          <v-text-field v-model="tutor.maternal_surname" hide-details filled dense
                            label="Apellido materno *"></v-text-field>
                          <v-text-field v-model="tutor.names" hide-details filled dense label="Nombre(s) *"
                            class="my-4"></v-text-field>
                          <v-text-field v-model="tutor.occupation" hide-details filled dense
                            label="Ocupación"></v-text-field>
                          <v-text-field v-model="tutor.street" hide-details filled dense label="Calle"
                            class="my-4"></v-text-field>
                          <v-text-field v-model="tutor.number" hide-details filled dense label="Número"></v-text-field>
                          <v-text-field v-model="tutor.colony" hide-details filled dense label="Colonia"
                            class="my-4"></v-text-field>
                          <v-text-field v-model="tutor.postal_code" hide-details filled dense
                            label="Código postal"></v-text-field>
                          <v-text-field v-model="tutor.municipality" hide-details filled dense label="Municipio"
                            class="my-4"></v-text-field>
                          <v-text-field v-model="tutor.city" hide-details filled dense label="Localidad"></v-text-field>
                          <v-text-field v-model="tutor.birth_date" type="date" hide-details filled dense
                            label="Fecha de nacimiento" class="my-4"></v-text-field>
                          <v-text-field v-model="tutor.curp" maxlength="18" hide-details filled dense
                            label="CURP *"></v-text-field>
                          <v-select :items="yes_no" v-model="tutor.knows_read" hide-details filled dense
                            label="¿Sabe leer?" class="my-4"></v-select>
                          <v-text-field v-model="tutor.scholarship" hide-details filled dense
                            label="Escolaridad"></v-text-field>
                          <v-text-field v-model="tutor.civil_status" hide-details filled dense label="Estado civil"
                            class="my-4"></v-text-field>
                          <v-text-field v-model="tutor.residence_state" hide-details filled dense
                            label="Entidad de residencia"></v-text-field>
                          <v-text-field v-model="tutor.phone"
                            onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                            maxlength="10" hide-details filled dense label="Teléfono" class="my-4"></v-text-field>
                          <v-text-field v-model="tutor.movil"
                            onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                            maxlength="10" hide-details filled dense label="Celular *"></v-text-field>
                          <v-row class="mt-4">
                            <v-col cols="6">
                              <v-text-field v-model="tr_mail_name" hide-details filled
                                label="Correo electrónico *"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-select v-model="tr_mail_domain" :items="mails" hide-details outlined single-line
                                prefix="@"></v-select>
                            </v-col>
                          </v-row>
                          <div class="text-body-2 mt-3 mb-4">
                            Registrado anteriormente:
                            {{
                              tutor.mail
                                ? tutor.mail
                                : "Sin correo (importante)"
                            }}
                          </div>
                          <v-expand-transition>
                            <div v-show="error">
                              <v-alert dense text type="error">
                                <div class="text-subtitle-2">
                                  {{ errorInfo }}
                                </div>
                              </v-alert>
                            </div>
                          </v-expand-transition>
                          <v-btn @click="saveTutor()" color="primary" dark elevation="0" class="mt-4">
                            <div class="text-subtitle-2 mx-2" style="text-transform: none">
                              {{
                                insert_tutor
                                  ? "Guardar tutor"
                                  : "Actualizar tutor"
                              }}
                            </div>
                          </v-btn>
                        </v-card-text>
                      </v-expand-transition>
                    </v-card>
                    <v-list-item @click="data_expand = false" link>
                      <v-list-item-title class="text-subtitle-2">
                        Ocultar datos personales
                      </v-list-item-title>
                      <v-list-item-action>
                        <v-icon>mdi-chevron-up</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                  </div>
                </v-expand-transition>
                <div class="text-h5 font-weight-bold text--primary mt-10">
                  {{ insert_ins ? "No inscrito(a)" : "Escolaridad" }}
                </div>
                <div class="text-subtitle-2 mt-2 mb-4">
                  Semestre y grupo en los que cursa el periodo actual
                  {{ period }}
                </div>
                <v-row class="mb-2">
                  <v-col cols="12" md="8">
                    <v-select v-model="student_active.semester" v-on:input="getGroups()" :items="semesters"
                      item-value="id" item-text="name" outlined hide-details label="Semestre"></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select v-model="student_active.groups" :items="groups" item-value="groups" item-text="groups"
                      no-data-text="Sin grupos" outlined hide-details label="Grupo"></v-select>
                  </v-col>
                </v-row>
                <v-expand-transition>
                  <div v-show="error">
                    <v-alert dense text type="error">
                      <div class="text-subtitle-2">{{ errorInfo }}</div>
                    </v-alert>
                  </div>
                </v-expand-transition>
                <v-container class="my-4">
                  <v-row>
                    <v-btn @click="saveActive()" color="primary" dark elevation="0"
                      :disabled="admin_permission ? false : !insert_ins">
                      <div class="text-subtitle-2 mx-2" style="text-transform: none">
                        {{
                          insert_ins ? "Inscribir alumno" : "Mover inscripción"
                        }}
                      </div>
                    </v-btn>
                    <v-btn @click="uninscription()" color="warning" dark elevation="0" class="ml-2"
                      :disabled="!editions">
                      <div class="text-subtitle-2 mx-2" style="text-transform: none">
                        Baja de alumno
                      </div>
                    </v-btn>
                  </v-row>
                </v-container>

                <v-divider v-if="admin_permission" class="mt-6"></v-divider>
                <v-list-item @click="delete_button = !delete_button" v-if="admin_permission" link>
                  <v-list-item-avatar>
                    <v-icon color="error">mdi-delete-outline</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title class="text-subtitle-2 error--text">
                    Eliminar alumno e inscripción (irreversible)
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-icon>
                      {{
                        delete_button ? "mdi-chevron-up" : "mdi-chevron-down"
                      }}
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider v-if="admin_permission"></v-divider>
                <div class="deleted" v-if="delete_button">
                  <v-card-text>
                    <v-alert type="error" outlined text>
                      <div class="text-body-2">
                        Esta acción es irreversible, no se podrán recuperar de
                        ninguna forma los registros de este alumno si se
                        eliminan definitivamente.
                      </div>
                      <v-checkbox v-model="deleting" color="error" label="Entiendo las consecuencias"></v-checkbox>
                    </v-alert>

                    <v-btn @click="uninscription()" color="primary" dark elevation="0" class="mt-4"
                      :disabled="!deleting">
                      <div class="text-subtitle-2 mx-2" style="text-transform: none">
                        Eliminar definitivamente
                      </div>
                    </v-btn>
                  </v-card-text>
                  <v-divider></v-divider>
                </div>

                <v-expand-transition>
                  <div class="receipt" v-if="!insert_ins">
                    <div class="text-h5 font-weight-bold text--primary mt-10">
                      Comprobante
                    </div>
                    <div class="text-subtitle-2 mt-2 mb-4">
                      Se puede reimprimir el comprobante de inscripción. Esto no supone ninguna administración contable.
                      La función únicamente es para generar el comprobante.
                    </div>
                    <v-row>
                      <v-col cols="12" sm="8" md="6" lg="4" xl="3">
                        <v-text-field v-model="currentDate" type="date" outlined dense hide-details
                          label="Fecha de impresión"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-btn @click="downloadReceipt()" color="primary" dark elevation="0" class="mt-4">
                      <div class="text-subtitle-2 mx-2" style="text-transform: none">
                        Imprimir comprobante
                      </div>
                    </v-btn>
                  </div>
                </v-expand-transition>

                <v-expand-transition>
                  <div class="receipt" v-if="!insert_ins">
                    <div class="text-h5 font-weight-bold text--primary mt-10">
                      Constancia
                    </div>
                    <div class="text-subtitle-2 mt-2 mb-4">
                      Imprima la constancia de estudios, seleccionando la fecha de generación, y si lleva o no sello y
                      firma digitales de la institución.
                    </div>
                    <v-row>
                      <v-col cols="12" lg="4" xl="3">
                        <v-text-field v-model="currentDate" type="date" outlined dense hide-details
                          label="Fecha de impresión"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" lg="4" xl="3">
                        <v-select v-model="sign" :items="[{ id: 1, name: 'Sí' }, { id: 0, name: 'No' }]" hide-details
                          dense item-value="id" item-text="name" outlined label="Firma digital"></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" lg="4" xl="3">
                        <v-select v-model="stamp" :items="[{ id: 1, name: 'Sí' }, { id: 0, name: 'No' }]" hide-details
                          dense item-value="id" item-text="name" outlined label="Sello digital"></v-select>
                      </v-col>
                    </v-row>
                    <v-btn @click="downloadConstancy()" color="primary" dark elevation="0" class="mt-4">
                      <div class="text-subtitle-2 mx-2" style="text-transform: none">
                        Imprimir constancia
                      </div>
                    </v-btn>
                  </div>
                </v-expand-transition>

                <v-expand-transition>
                  <div class="receipt" v-if="!insert_ins">
                    <div class="text-h5 font-weight-bold text--primary mt-10">
                      Solicitud de inscripción
                    </div>
                    <div class="text-subtitle-2 mt-2 mb-4">
                      Imprima la solicitud de inscripción del alumno considerando que solo saldrán los campos que se
                      tienen registrados en el expediente del mismo.
                    </div>
                    <v-row>
                      <v-col cols="12" lg="4" xl="3">
                        <v-text-field v-model="currentDate" type="date" outlined dense hide-details
                          label="Fecha de impresión"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-btn @click="downloadRequest()" color="primary" dark elevation="0" class="mt-4">
                      <div class="text-subtitle-2 mx-2" style="text-transform: none">
                        Imprimir solicitud
                      </div>
                    </v-btn>
                  </div>
                </v-expand-transition>

                <v-expand-transition>
                  <div class="not-taken" v-show="!insert_ins">
                    <div class="text-h5 font-weight-bold text--primary mt-10">
                      Carga académica
                    </div>
                    <div class="text-subtitle-2 mt-2 mb-4">
                      El alumno cursará
                      <span class="text--primary"> todas las materias </span>
                      que correspondan al mapa curricular {{ period }} de
                      {{ student_active.semester }}° semestre,
                      <strong class="primary--text">
                        con excepción de las siguientes (NO CURSADAS):
                      </strong>
                    </div>
                    <v-list-item v-for="not_taken in not_takens" :key="not_taken.id" link>
                      <v-list-item-avatar>
                        <v-img lazy-src="../../../../assets/images/default.png" :src="require('../../../../assets/subjects/' +
                          not_taken.icon +
                          '.png')
                          "></v-img>
                      </v-list-item-avatar>
                      <v-list-item-title class="text-subtitle-2">
                        {{ not_taken.name }}
                        <span class="text--disabled">(No cursada)</span>
                      </v-list-item-title>
                      <v-list-item-action>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="takenSubject(not_taken.id)" icon color="error" v-bind="attrs" v-on="on">
                              <v-icon> mdi-close </v-icon>
                            </v-btn>
                          </template>
                          <span> Reinscribir </span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                    <div class="text-subtitle-2 text--primary mt-8">
                      Omitir (no cursar) esta materia:
                    </div>
                    <v-select v-model="curriculum" :items="curriculums" item-value="id" item-text="name" filled dense
                      clearable hide-details label="Buscar materia" no-data-text="Sin materias" class="my-4"></v-select>
                    <v-expand-transition>
                      <div v-show="error">
                        <v-alert dense text type="error">
                          <div class="text-subtitle-2">{{ errorInfo }}</div>
                        </v-alert>
                      </div>
                    </v-expand-transition>
                    <v-btn @click="quitSubject()" color="primary" outlined text>
                      <div class="text-subtitle-2 mx-2" style="text-transform: none">
                        Omitir materia
                      </div>
                    </v-btn>
                    <div class="text-h5 font-weight-bold text--primary mt-10">
                      Clínica
                    </div>
                    <div class="text-subtitle-2 mt-2 mb-4">
                      Estado clínico y registros del alumno
                    </div>
                  </div>
                </v-expand-transition>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-slide-y-reverse-transition>
      <v-dialog v-model="class_details" width="500">
        <v-card style="border-radius: 10px" :style="{
          background: $vuetify.theme.themes[theme].images,
        }">
          <v-list-item>
            <v-list-item-avatar>
              <v-img lazy-src="../../../../assets/images/default.png" :src="require('../../../../assets/subjects/' + cl_icon + '.png')
                "></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <div class="text-subtitle-2">{{ cl_subject }}</div>
              <div class="text-caption">Por: {{ cl_teacher }}</div>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click="class_details = false" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <div class="text-center">
            <v-avatar :style="{
              background: $vuetify.theme.themes[theme].background,
            }" size="100" class="my-2">
              <div class="text-h3" :class="cl_complementary ? '' : cl_number >= 6 ? '' : 'error--text'
                ">
                {{ cl_number }}
              </div>
            </v-avatar>
            <div class="text-caption" :class="cl_complementary ? '' : cl_number >= 6 ? '' : 'error--text'
              ">
              {{ cl_letter }}
            </div>
          </div>
          <v-divider class="my-4"></v-divider>
          <v-card-text>
            <div class="text-subtitle-2 text--primary">
              Detalles ({{ period_model.partial }}° parcial)
            </div>
            <div class="text-subtitle-2 text--primary my-2">
              <v-icon small class="mr-2">mdi-clipboard-list-outline</v-icon>
              {{ cl_assists }}
            </div>
            <div class="text-subtitle-2 text--primary my-2">
              <v-icon small class="mr-2">
                mdi-file-table-box-multiple-outline
              </v-icon>
              {{ cl_evidences }}
            </div>
            <div class="text-subtitle-2 text--primary mt-2">
              <v-icon small class="mr-2"> mdi-file-star-outline </v-icon>
              {{ cl_test }}
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="downloading" full-screen persistent :no-click-animation="true">
        <v-snackbar v-model="downloading" color="primary" dark border="left" :timeout="-1" bottom left>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-download-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <div class="text-subtitle-2">Descargando...</div>
              <div class="text-body-2">Tiempo máximo: 120 seg.</div>
            </v-list-item-content>
            <v-list-item-action>
              <v-progress-circular indeterminate></v-progress-circular>
            </v-list-item-action>
          </v-list-item>
        </v-snackbar>
      </v-dialog>
      <v-snackbar v-model="responseView" timeout="2000" color="info" top right>
        <div class="text-center text-subtitle-2">{{ responseMessage }}</div>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import auth from "../../../../plugins/auth";
import settings from "../../../../plugins/settings";
import periods from "../../../../plugins/periods";
import students from "../../../../plugins/students";
import students_active from "../../../../plugins/students_active";
import not_taken from "../../../../plugins/not_taken";
import curriculum from "../../../../plugins/curriculum";
import partial from "../../../../plugins/partial";
import tutors from "../../../../plugins/tutors";
import groups from "../../../../plugins/groups";
import files from "../../../../plugins/files";
import reports from "../../../../plugins/reports";
import assists from "../../../../plugins/assists";
import evidences from "../../../../plugins/evidences";

// resources
import endpoint from "../../../../plugins/endpoint";
import profile_img from "../../../../assets/images/profile.png";
import functions from "../../../../plugins/functions";
import assists_list from "../../../../plugins/assists_list";
import moment from "moment";

export default {
  name: "Search",

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  created() {
    this.main();
  },

  data: () => ({
    currentDate: moment().format('YYYY-MM-DD'),

    loading: false,
    compiler: false,
    locked: true,
    custom: false,

    delete_button: false,
    deleting: false,

    period: "",
    period_model: {},

    // Periodo dinámico: 28 de julio 2024
    periods: [],
    selectedPeriod: "",
    selectedActive: {},

    photo_url: profile_img,
    photo: [],
    back_url: "",

    insert: false,
    insert_tutor: true,
    tutor_expand: false,

    insert_ins: true,

    // No cursadas
    not_takens: [],
    not_taken: {},
    curriculums: [],
    curriculum: 0,
    group_selection: {},

    // Expanders
    data_expand: false,
    status_expand: false,

    cardex_list: [],

    // Detalles de la clase
    class_details: false,
    cl_icon: "Ninguna",
    cl_subject: "",
    cl_teacher: "",
    cl_number: "",
    cl_letter: "",
    cl_assists: "",
    cl_evidences: "",
    cl_test: "",
    cl_complementary: false,

    editions: false,

    genders: [
      { id: "F", name: "FEMENINO" },
      { id: "M", name: "MASCULINO" },
    ],
    semesters: [
      { id: 1, name: "Primer semestre" },
      { id: 2, name: "Segundo semestre" },
      { id: 3, name: "Tercer semestre" },
      { id: 4, name: "Cuarto semestre" },
      { id: 5, name: "Quinto semestre" },
      { id: 6, name: "Sexto semestre" },
    ],
    groups: [],

    student: {},
    // student mail
    st_mail_name: "",
    st_mail_domain: "alumno.msev.gob.mx",
    student_active: {},

    // Tutor del estudiante
    tutor: {},
    // tutor mail
    tr_mail_name: "",
    tr_mail_domain: "hotmail.com",
    tutors: [],

    downloading: false,

    // Correos
    mails: [
      "alumno.msev.gob.mx",
      "hotmail.com",
      "hotmail.es",
      "hotmail.com.es",
      "outlook.com",
      "outlook.es",
      "outlook.com.es",
      "gmail.com",
      "yahoo.com",
      "yahoo.es",
      "yahoo.com.es",
    ],

    blood_types: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
    yes_no: ["SI", "NO"],
    partials: [
      { id: 1, name: "Primer parcial" },
      { id: 2, name: "Segundo parcial" },
      { id: 3, name: "Tercer parcial" },
    ],

    // Impresion de constancia 2/25
    sign: 0,
    stamp: 0,

    admin_permission: true,

    error: false,
    errorInfo: "",

    responseView: false,
    responseMessage: "",
  }),

  methods: {
    // start chain
    async main() {
      if (!auth.getSession()) this.$router.push("/");
      else {
        this.loading = true;
        this.back_url = endpoint.BASE_PATH;

        await auth.permission().then(async (response) => {
          if (response.data.directive == "admin") this.getSettings();
          else if (response.data.directive == "supervisor") {
            this.admin_permission = false;
            this.getSettings();
          } else this.$router.push("/");
        });
      }
    },

    async getSettings() {
      await settings.getOne(1).then((response) => {
        this.period = response.data.period;
        this.editions = response.data.editions;

        this.getPeriod();
      });
    },

    async getPeriod() {
      await periods.getOne(this.period).then((response) => {
        this.period_model = response.data;

        this.getStudent();
      });
    },

    async getStudent() {
      await students.getOne(this.$route.params.id).then((response) => {
        if (response.data.message == "error") this.insert = true;
        else {
          this.student = response.data;

          if (this.student.mail != null && this.student.mail != "") {
            var mail = this.student.mail.toLowerCase().split("@");
            this.st_mail_name = mail[0];
            this.st_mail_domain = mail[1];
          }

          this.photo_url = this.back_url + this.student.photo;
          this.getTutors();
        }
      });
    },

    async getTutors() {
      await tutors.get(this.student.id).then((response) => {
        this.tutors = response.data;

        this.tutor = {};
        if (this.student.status != 0) this.getActive();
        else {
          this.curriculum = 0;
          this.loading = false;
          this.compiler = true;
        }
      });
    },

    async getActive() {
      await students_active
        .getByStudent(this.student.id, this.period)
        .then(async (response) => {
          if (response.data.status == "error") {
            this.insert_ins = true;
            this.curriculum = 0;
            this.loading = false;
            this.compiler = true;
          } else {
            this.student_active = response.data;
            this.insert_ins = false;
            this.getGroups();

            // Obtener el grupo
            await groups
              .getOne(
                this.student_active.semester,
                this.student_active.groups,
                this.period
              )
              .then(async (response) => {
                this.group_selection = response.data;

                // Obtener las materias por el semestre y grupo
                await curriculum
                  .getBySemester(this.student_active.semester, this.period)
                  .then(async (response) => {
                    if (
                      this.student_active.semester == 5 ||
                      this.student_active.semester == 6
                    ) {
                      this.curriculums = Object.values(response.data).filter(
                        (area) => area.area === this.group_selection.area
                      );
                    } else this.curriculums = response.data;

                    await this.getPeriods();

                    this.getNotTakens();
                  });
              });
          }
        });
    },

    async getNotTakens() {
      this.loading = true;
      await not_taken.get(this.student.id, this.period).then((response) => {
        this.not_takens = response.data;

        this.getStatus(true);
      });
    },

    // invoked
    async saveStudent(reload) {
      this.student.mail = this.st_mail_name + "@" + this.st_mail_domain;
      if (
        this.student.paternal_surname != "" &&
        this.student.paternal_surname != null &&
        this.student.maternal_surname != "" &&
        this.student.maternal_surname != null &&
        this.student.names != "" &&
        this.student.names != null &&
        this.student.mail != "" &&
        this.student.mail != null &&
        this.student.curp != "" &&
        this.student.curp != null
      ) {
        if (this.mailValidate(this.student.mail.trim().toLowerCase())) {
          this.loading = true;
          // mayusculas
          this.uppercaseModel();

          await students
            .update(this.student.id, this.student)
            .then(async (response) => {
              this.responseMessage = response.data.message;
              this.responseView = true;

              this.data_expand = false;
              this.loading = false;
              if (reload) location.reload();
            });
        } else this.showError("Correo electrónico no válido");
      } else this.showError("Completar campos obligatorios (*)");
    },

    async saveActive() {
      if (
        this.student_active.semester != "" &&
        this.student_active.semester != null &&
        this.student_active.groups != "" &&
        this.student_active.groups != null
      ) {
        this.buildReg();
      } else this.showError("Seleccionar el semestre y el grupo");
    },

    async uploadPhoto() {
      this.loading = true;

      const formData = new FormData();
      formData.append("file", this.photo);

      await files.studentsPhoto(formData, this.student.id).then((response) => {
        this.student.photo = response.data.path;
        console.log(this.student.photo);
        this.saveStudent(true);
      });
    },

    // tutores
    async getTutor(id) {
      await tutors.getOne(id).then((response) => {
        this.tutor = response.data;

        if (this.tutor.mail != null && this.tutor.mail != "") {
          var mail = this.tutor.mail.toLowerCase().split("@");
          this.tr_mail_name = mail[0];
          this.tr_mail_domain = mail[1];
        }

        this.insert_tutor = false;
        this.tutor_expand = true;
      });
    },

    async saveTutor() {
      this.tutor.mail = this.tr_mail_name + "@" + this.tr_mail_domain;
      if (
        this.tutor.paternal_surname != "" &&
        this.tutor.paternal_surname != null &&
        this.tutor.maternal_surname != "" &&
        this.tutor.maternal_surname != null &&
        this.tutor.names != "" &&
        this.tutor.names != null &&
        this.tutor.curp != "" &&
        this.tutor.curp != null &&
        this.tutor.phone != "" &&
        this.tutor.phone != null &&
        this.tutor.mail != "" &&
        this.tutor.mail != null
      ) {
        if (this.mailValidate(this.tutor.mail.trim().toLowerCase())) {
          this.loading = true;
          this.uppercaseModelTutor();
          if (this.insert_tutor)
            await tutors.insert(this.tutor).then((response) => {
              this.responseMessage = response.data.message;
              this.responseView = true;

              this.getTutors();
              this.tutor_expand = false;
              this.loading = false;
            });
          else
            await tutors.update(this.tutor.id, this.tutor).then((response) => {
              this.responseMessage = response.data.message;
              this.responseView = true;

              this.getTutors();
              this.tutor_expand = false;
              this.loading = false;
            });
        } else this.showError("Correo electrónico no válido (*)");
      } else this.showError("Completar campos obligatorios (*)");
    },

    expandTutor() {
      this.tutor_expand = !this.tutor_expand;
      this.insert_tutor = true;
      this.tutor = {};
    },

    // Carga academica (materias no cursadas)
    async quitSubject() {
      if (this.curriculum != 0 && this.curriculum != null) {
        this.loading = true;

        this.not_taken.subject = this.curriculum;
        this.not_taken.student = this.student.id;
        this.not_taken.period = this.period;

        await not_taken.insert(this.not_taken).then((response) => {
          this.responseMessage = response.data.message;

          this.getNotTakens(this.student.id);
          this.responseView = true;
          this.loading = false;
        });
      } else this.showError("Seleccionar una materia para omitir");
    },

    async takenSubject(id) {
      this.loading = true;
      await not_taken.delete(id).then((response) => {
        this.responseMessage = response.data.message;

        this.getNotTakens(this.student.id);
        this.responseView = true;
        this.loading = false;
      });
    },

    // Complementos para cambiar periodo
    async getStatus(first) {
      await this.getSelectedActive();
      this.loading = true;
      this.status_expand = false;
      await partial
        .getCardex(this.period_model.partial, this.student.id, this.selectedPeriod)
        .then((response) => {
          this.cardex_list = response.data;
          //console.log(this.cardex_list);

          this.loading = false;
          if (!first) this.status_expand = true;
          this.curriculum = 0;
          this.compiler = true;
        });
    },

    async getSelectedActive() {
      await students_active
        .getByStudent(this.student.id, this.selectedPeriod)
        .then(async (response) => {
          // Si es error, dehabilita el boton de boleta
          if (response.data.status == "error") this.selectedActive = null;
          else this.selectedActive = response.data;
        });
    },

    async getPeriods() {
      this.loading = true;
      await periods.get().then((response) => {
        this.periods = response.data;
        if (this.periods.length > 0) this.selectedPeriod = this.period;
      });
    },

    //  fn

    async openStatus(
      icon,
      subject,
      teacher,
      mail,
      number,
      letter,
      assist_info,
      evidences_info,
      evidences_total,
      test,
      test_total,
      lesson,
      complementary
    ) {
      this.loading = true;
      this.cl_icon = icon;
      this.cl_subject = subject;
      this.cl_teacher = teacher;
      if (complementary) {
        if (number >= 6) {
          this.cl_number = "A";
          this.cl_letter = "APROBADA";
        } else {
          this.cl_number = "N/A";
          this.cl_letter = "NO APROBADA";
        }
      } else {
        this.cl_number = number;
        this.cl_letter = letter;
      }
      this.cl_complementary = complementary;
      // Número de asistencias (pases de lista)
      await assists
        .getTotal(this.period, this.period_model.partial, lesson)
        .then(async (response) => {
          var ast = parseFloat(response.data.total - assist_info);
          console.log(
            "Del " +
            this.period_model.partial +
            " parcial, son " +
            response.data.total +
            ", y el alumno tiene " +
            assist_info +
            " faltas, lo que da: " +
            ast +
            " asistencias"
          );
          this.cl_assists = ast + " de " + response.data.total + " asistencias";

          // Número de evidencias calificadas
          await evidences
            .getTotal(this.period, this.period_model.partial, lesson)
            .then((response) => {
              this.cl_evidences =
                evidences_info +
                " de " +
                response.data.total +
                " evidencias (" +
                evidences_total +
                "%)";

              this.cl_test = test + " de examen (" + test_total + "%)";
              this.class_details = true;
              this.loading = false;
            });
        });
    },

    // Imprimir boleta
    async ratingsPDF() {
      this.downloading = true;
      var fileDownload = require("js-file-download");
      await reports
        .getRatings(this.selectedActive.id, this.period_model.partial)
        .then((response) => {
          fileDownload(
            response.data,
            "Boleta " +
            this.period_model.partial +
            "° parcial (" +
            this.student.paternal_surname +
            " " +
            this.student.maternal_surname +
            " " +
            this.student.names +
            ").pdf"
          );
          this.downloading = false;
        });
    },

    // Eliminar a un alumno (delicado)
    // -- Se elimina tanto el registro como el alumno (DELICADISIMO)
    async uninscription() {
      this.loading = true;
      await students_active
        .delete(this.student_active.id)
        .then(async (response) => {
          console.log(response.data.message);

          await students.delete(this.student.id).then((response) => {
            console.log(response.data.message);
            this.loading = false;
            this.back();
          });
        });
    },

    back() {
      this.$router.push("/ad/ins");
    },

    // support
    showError(message) {
      this.error = true;
      this.errorInfo = message;
      setTimeout(() => (this.error = false), 3000);
    },

    mailValidate(mail) {
      if (
        /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i.test(
          mail
        )
      )
        return true;
      else return false;
    },

    uppercaseModel() {
      // Datos en mayusculas y sin acentos
      this.student.paternal_surname = functions.normalizeInput(
        this.student.paternal_surname.trim().toUpperCase()
      );
      this.student.maternal_surname = functions.normalizeInput(
        this.student.maternal_surname.trim().toUpperCase()
      );
      this.student.names = functions.normalizeInput(
        this.student.names.trim().toUpperCase()
      );
      this.student.mail = this.student.mail.trim().toLowerCase();
      this.student.curp = this.student.curp.trim().toUpperCase();
      this.student.street = this.student.street.trim().toUpperCase();
      this.student.colony = this.student.colony.trim().toUpperCase();
      this.student.city = this.student.city.trim().toUpperCase();
      this.student.municipality = this.student.municipality
        .trim()
        .toUpperCase();
      this.student.birth_city = this.student.birth_city.trim().toUpperCase();
      this.student.birth_municipality = this.student.birth_municipality
        .trim()
        .toUpperCase();
      this.student.birth_state = this.student.birth_state.trim().toUpperCase();
      this.student.nationality = this.student.nationality.trim().toUpperCase();
    },

    uppercaseModelTutor() {
      // Datos en mayusculas
      this.tutor.paternal_surname = this.tutor.paternal_surname
        .trim()
        .toUpperCase();
      this.tutor.maternal_surname = this.tutor.maternal_surname
        .trim()
        .toUpperCase();
      this.tutor.names = this.tutor.names.trim().toUpperCase();
      this.tutor.occupation = this.tutor.occupation.trim().toUpperCase();
      this.tutor.street = this.tutor.street.trim().toUpperCase();
      this.tutor.colony = this.tutor.colony.trim().toUpperCase();
      this.tutor.city = this.tutor.city.trim().toUpperCase();
      this.tutor.municipality = this.tutor.municipality.trim().toUpperCase();
      this.tutor.curp = this.tutor.curp.trim().toUpperCase();
      this.tutor.scholarship = this.tutor.scholarship.trim().toUpperCase();
      this.tutor.civil_status = this.tutor.civil_status.trim().toUpperCase();
      this.tutor.residence_state = this.tutor.residence_state
        .trim()
        .toUpperCase();
      this.tutor.mail = this.tutor.mail.trim().toLowerCase();

      // Datos faltantes
      this.tutor.student = this.student.id;
    },

    async getGroups() {
      await groups
        .get(this.student_active.semester, this.period)
        .then((response) => {
          this.groups = response.data;
        });
    },

    async buildReg() {
      if (this.student.status == 0) {
        // Datos de la tabla cei_students (estudiante: matricula y estado)
        var enroll = this.period.split("-");
        var st = enroll[0];
        var nd = enroll[1];
        var first = st.split("20");
        var second = nd.split("20");
        this.student.enrollment =
          "BAC" + first[1] + second[1] + "0" + this.student.id;

        // Va a estar activo (Al seleccionar su semestre y grupo)
        this.student.status = 1;
      }

      // Actualización e inserción de inscripción
      let date = new Date();

      await students
        .update(this.student.id, this.student)
        .then(async (response) => {
          // Datos de la tabla cei_students_active (inscripcion actual)
          this.student_active.student = this.student.id;
          this.student_active.period = this.period;
          this.student_active.inscription_date =
            date.getUTCFullYear() +
            "-" +
            date.getUTCMonth() +
            "-" +
            date.getUTCDay();
          this.student_active.inscription_hour = date.getHours();
          this.student_active.inscription_user = "";

          // Va a estar activo (Al seleccionar su semestre y grupo)
          this.student_active.status = 1;

          this.responseMessage = response.data.message;
          this.responseView = true;

          if (this.insert_ins) {
            console.log(this.student_active);
            await students_active
              .insert(this.student_active)
              .then(async (response) => {
                this.responseMessage = response.data.message;

                await this.downloadReceipt();

                this.loading = false;
                this.back();
              });
          } else
            await students_active
              .update(this.student_active.id, this.student_active)
              .then(async (response) => {
                this.responseMessage = response.data.message;

                await this.downloadReceipt();

                this.loading = false;
                this.back();
              });
        });
    },

    // Impresion de un recibo: 28 de julio 2024
    async downloadReceipt() {
      if (this.currentDate) {
        this.downloading = true;
        var fileDownload = require("js-file-download");
        await reports
          .receipt(this.student.id, this.period, moment(this.currentDate).format('YYYY-MM-DD'))
          .then((response) => {
            fileDownload(
              response.data, `${this.student_active.semester}_${this.student_active.groups}_${this.student.paternal_surname}_${this.student.maternal_surname}_${this.student.names}_${this.student_active.id}.pdf`
            );
            this.downloading = false;
          });
      }
    },

    // Impresion de constancia 17 de febrero de 2025
    async downloadConstancy() {
      if (this.currentDate) {
        this.downloading = true;
        var fileDownload = require("js-file-download");
        await reports
          .constancy(this.student.id, this.period, moment(this.currentDate).format('DD-MM-YYYY'), this.sign, this.stamp)
          .then((response) => {
            fileDownload(
              response.data, `CONSTANCIA_${this.student_active.semester}_${this.student_active.groups}_${this.student.paternal_surname}_${this.student.maternal_surname}_${this.student.names}_${this.student_active.id}.pdf`
            );
            this.downloading = false;
          });
      }
    },

    // Impresión de solicitud de inscripción 28 de febrero de 2025
    async downloadRequest() {
      if (this.currentDate) {
        this.downloading = true;
        var fileDownload = require("js-file-download");
        await reports
          .inscription('inscription-request', this.student.id, this.period, moment(this.currentDate).format('DD-MM-YYYY'))
          .then(async (response) => {
            fileDownload(
              response.data, `SOLICITUD_ANVERSO_${this.student_active.semester}_${this.student_active.groups}_${this.student.paternal_surname}_${this.student.maternal_surname}_${this.student.names}_${this.student_active.id}.pdf`
            );

            await reports
              .inscription('inscription-reverse', this.student.id, this.period, moment(this.currentDate).format('DD-MM-YYYY'))
              .then((response) => {
                fileDownload(
                  response.data, `SOLICITUD_REVERSO_${this.student_active.semester}_${this.student_active.groups}_${this.student.paternal_surname}_${this.student.maternal_surname}_${this.student.names}_${this.student_active.id}.pdf`
                );
                this.downloading = false;
              });
          });
      }
    }

  },
};
</script>