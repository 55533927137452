import axios from "axios";
import end from './endpoint';
const ENDPOINT = end.PATH + "reports/";
const RPT_ENDPOINT = end.RPT_PATH;

export default {
    // --- REINCO ---
    getReinco(type, subject, lesson, semester, group, period, partial) {
        const path = ENDPOINT + subject + "/" + lesson + "/" + semester + "/" + group + "/" + period + "/" + partial + "/" + type;
        return axios({ url: path, method: 'GET', responseType: 'blob' });
    },

    getExamples(report) {
        const path = ENDPOINT + report;
        return axios({ url: path, method: 'GET', responseType: 'blob' });
    },

    // --- ratings ---
    getRatings(inscription, partial) {
        const path = ENDPOINT + inscription + "/" + partial;
        return axios({ url: path, method: 'GET', responseType: 'blob' });
    },

    // --- inscriptions ---
    receipt(student, period, date) {
        return axios({ url: `${RPT_ENDPOINT}inscription-receipt/${student}/${period}/${date}`, method: 'GET', responseType: 'blob' });
    },
    constancy(student, period, date, sign, stamp) {
        return axios({ url: `${RPT_ENDPOINT}constancy/${student}/${period}/${date}/${sign}/${stamp}`, method: 'GET', responseType: 'blob' });
    },
    inscription(reversed, student, period, date) {
        return axios({ url: `${RPT_ENDPOINT}${reversed}/${student}/${period}/${date}`, method: 'GET', responseType: 'blob' });
    },

    pdfActiveStudents(semester, groups, period) {
        const path = ENDPOINT + semester + "/" + groups + "/" + period;
        return axios({ url: path, method: 'GET', responseType: 'blob' });
    },

    excelActiveStudents(semester, groups, period) {
        const path = ENDPOINT + "excel/" + semester + "/" + groups + "/" + period;
        return axios({ url: path, method: 'GET', responseType: 'blob' });
    },
};